import Skeleton from '../components/skeleton/skeleton'
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MyBIds = ({ accessToken }) => {
    const [bids, setBids] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserBids = async () => {
            try {
                const response = await axios.get('https://backend.aslikahani.com/bidding/v1/auction/user-bids', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setBids(response.data);
                setLoading(false);
            } catch (error) {
                if(error.response.status==401){
                    navigate("/login");
                }
                console.error('Error fetching user bids:', error);
                setError('Failed to fetch user bids.');
                setLoading(false);
            }
        };

        fetchUserBids();
    }, [accessToken]);

    if (loading) {
        return <div className='my-bids start'><div className='activity showNoData'><p>Loading...</p></div></div>;
    }

    if (error) {
        return <div className='my-bids start'><div className='activity showNoData'><p>{error}</p></div></div>;
    }

    if (bids.bids_history.length === 0) {
        return <div className='my-bids start'>
            <div className='activity showNoData'>

                <p>No bids available.</p>
            </div>
        </div>;
    }
    return (
        <>
            <div className="my-bids start">
                <div className="activity">
                    <div className="title">
                        <h4>My Activity</h4>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/bid01.png`)} className="img-fluid" alt="" />
                                </div>
                                <div className="content">
                                    <div className="timer" data-to="80" data-speed="5000">{bids.total_bids}</div>
                                    <span>Total Bids</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/bid02.png`)} className="img-fluid" alt="" />
                                </div>
                                <div className="content">
                                    <div className="timer" data-to="15" data-speed="5000">{bids.bids_won}</div>
                                    <span>Items Won</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/bid03.png`)} className="img-fluid" alt="" />
                                </div>
                                <div className="content">
                                    <div className="timer" data-to="115" data-speed="5000">115</div>
                                    <span>Favorites</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="history-table">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Auction Name</th>
                                    <th>Amount</th>
                                    {/* <th>Is Successful</th> */}
                                    <th>Bid Submitted On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bids.bids_history.map((bid, index) => (
                                    <tr key={index}>
                                        <td><Link to={"/auction/" + bid.auction_url}>{bid.auction_name}</Link></td>
                                        <td>{bid.amount}</td>
                                        {/* <td>{bid.is_successful ? 'Yes' : 'No'}</td> */}
                                        <td>{bid.bid_submitted_on}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyBIds;