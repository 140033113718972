import axios from 'axios';

const API_URL = 'https://backend.aslikahani.com/purity/v1/auth/generate-otp?phone_number=7011141844';

const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${API_URL}/refresh-token`, { refreshToken });
        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken);
        return accessToken;
    } catch (error) {
        console.error('Failed to refresh access token', error);
        throw error;
    }
};

// Axios instance with request interceptor
const axiosInstance = axios.create({
    baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        let accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            accessToken = await refreshAccessToken();
        }
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const generateOtp = async (phone) => {
    return await axiosInstance.post('/generate-otp', { phone });
};

export const verifyOtp = async (phone, otp) => {
    return await axiosInstance.post('/verify-otp', { phone, otp });
};

export const login = async (phone, otp) => {
    const response = await axiosInstance.post('/login', { phone, otp });
    const { accessToken, refreshToken } = response.data;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    return response.data;
};

export const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};
