

import img from "../assets/images/about.png"
import CatagorySlider from '../components/catagorySlider';
import Auction from '../components/auction';
import RegisterWrapper from '../components/registerWrapper';
import PopularAuction from '../components/popularAuctions';
import HowItWorks from '../components/howItWorks';
import CommentsSlider from '../components/comments';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const HomeLanding = () => {

    return (<>
        <main>
            {/* <!-- Bannar --> */}
            <section id="banner" className="topBanner-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="banner-content">
                                <h5>Next Generation Auction</h5>
                                <h1>
                                    Find Your
                                    <span>Next Deal!</span>
                                </h1>
                                <p>Online Auction is where everyone goes to shop, sell and give, while discovering variety
                                    and affordability.</p>
                                <Link to="/login" className="login">GET STARTED</Link>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            {/* <img src={require(`../assets/images/banner-1.png`)} alt=""/> */}
                            <img src={require(`../assets/images/banner.png`)} alt="" />
                        </div>
                    </div>
                </div>
                <div className="banner-bottom-image d-none d-lg-block">
                    <img src={require(`../assets/images/banner-shape.png`)} alt="" className="img-fluid" />
                </div>
            </section>
            {/* <!-- End Banner --> */}
            <CatagorySlider />
            <Auction categoryName="Rings" />
            <RegisterWrapper />
            {/* <!-- Watches Auction --> */}
            <Auction categoryName="Necklace" />
            {/* <!-- Emd Watches Auction --> */}
            <PopularAuction />
            {/* <!-- Coins & Bullion --> */}
            <Auction categoryName="Coins" />
            {/* <!-- End Coins & Bullion --> */}
            <HowItWorks />
            <CommentsSlider />
        </main>
    </>

    );
};
export default HomeLanding;