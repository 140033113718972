// import './assets/css/flaticon.css'
// import './assets/css/style.css'
// import About from './pages/aboutUs';
// import Contact from './pages/contact';
// import Footer from './components/footer/footer';
// import AuctionDetails from './components/auctionDetails';
// import UserProfile from './pages/profile';
// import ViewActions from './pages/viewAllAuctions';
// import ScrollToTop from './components/scrollTop';
// import 'bootstrap/dist/css/bootstrap.min.css';



// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './pages/home';
// import OtpLogin from './pages/otp';
// import Header from './components/header/header'

// const App = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [accessToken, setAccessToken] = useState(null);

//   useEffect(() => {
//     const token = localStorage.getItem('accessToken');
//     console.log('Access token in localStorage on mount:', token);
//     if (token) {
//       setAccessToken(token);
//       setIsLoggedIn(true);
//     }
//   }, []);

//   return (
//     <Router>
//       <ScrollToTop/>
//       <Header isLoggedIn={isLoggedIn} />
//       <Routes>
//         <Route path="/" element={<Home isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
//         <Route path="/login" element={<OtpLogin setIsLoggedIn={setIsLoggedIn} />} />
//            <Route path="/about" element={<About/>} />
//            <Route path="/contact" element={<Contact/>} />
//            <Route path="/auction/:details"  element={<AuctionDetails/>} />
//            <Route path="/UserProfile"  element={<UserProfile isLoggedIn={isLoggedIn} accessToken={accessToken}/>} />
//            <Route path="/bidding/auction-list/:category" element={<ViewActions/>}/>
//       </Routes>
//         <Footer />
//     </Router>
//   );
// };

// export default App;


import './assets/css/flaticon.css';
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import About from './pages/aboutUs';
import Contact from './pages/contact';
import Footer from './components/footer/footer';
import AuctionDetails from './components/auctionDetails';
import UserProfile from './pages/profile';
import ViewActions from './pages/viewAllAuctions';
import ScrollToTop from './components/scrollTop';
import Home from './pages/home';
import OtpLogin from './pages/otp';
import Header from './components/header/header';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    console.log('Access token in localStorage on mount:', token);
    if (token) {
      setAccessToken(token);
      setIsLoggedIn(true);
      // toast.success('Welcome back!');
    } 
    // else {
    //   toast.info('Please log in to continue.');
    // }
  }, []);

  const handleLoginSuccess = (token) => {
    setAccessToken(token);
    setIsLoggedIn(true);
    localStorage.setItem('accessToken', token);
    toast.success('Logged in successfully!');
  };

  const PrivateRoute = ({ element: Component, ...rest }) => {
    return isLoggedIn ? (
      <Component {...rest} />
    ) : (
      <Navigate to="/login" replace />
    );
  };

  return (
    <Router>
      <ScrollToTop />
      <Header isLoggedIn={isLoggedIn} />
      <Routes>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
        <Route path="/login" element={<OtpLogin setIsLoggedIn={setIsLoggedIn} onLoginSuccess={handleLoginSuccess} />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/auction/:details" element={<AuctionDetails />} />
        <Route
          path="/UserProfile"
          element={<PrivateRoute element={UserProfile} isLoggedIn={isLoggedIn} accessToken={accessToken} />}
        />
        <Route path="/bidding/auction-list/:category" element={<ViewActions />} />
      </Routes>
      <Footer />
      <ToastContainer /> {/* Toast container to display the toasts */}
    </Router>
  );
};

export default App;
