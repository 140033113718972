
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons'

const AuctionItem = ({ auction }) => {
    return (
        //   <div className="auction-item">
        //     <img src={auction.entity.primary_image} alt={auction.name} />
        //     <h3>{auction.name}</h3>
        //     <p>{auction.entity.short_description}</p>
        //     <p>Base Price: ${auction.base_price}</p>
        //     <p>Max Selling Price: ${auction.max_selling_price}</p>
        //     <p>Visibility Until: {new Date(auction.visibility_until).toLocaleString()}</p>
        //   </div>

        <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="auction-item">
                <div className="image">
                    <Link to={"/auction/" + auction.url}>
                        <img src={auction.entity.primary_image} alt={auction.name} />
                    </Link>
                    <Link to={"/auction/" + auction.url} className="star">
                        <i className="far fa-star"></i>
                        <FontAwesomeIcon icon={faStar} />
                    </Link>
                    <Link to={"/auction/" + auction.url} className="hammer">
                        <i className="flaticon-hammer"></i>
                    </Link>
                </div>
                <div className="auction-content">
                    <h5>{auction.name}</h5>
                    <div className="bid d-flex justify-content-between">
                        <div className="current-bid d-flex">
                            <i className="flaticon-hammer"></i>
                            <p className="d-flex flex-column">Current Bid
                                <span>₹{auction.max_selling_price}</span>
                            </p>
                        </div>
                        <div className="buy-now-price d-flex">
                            <i className="flaticon-money"></i>
                            <p className="d-flex flex-column">Buy Now
                                <span>₹{auction.base_price}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="time d-flex ">
                    <div className="countdown" data-countdown="10/31/2020 20:00">{auction.visibility_until}</div>
                    <span>{auction.total_bid_count} Bids</span>
                </div>
                <div className="button text-center">
                    <Link to={"/auction/" + auction.url}>Submit A Bid</Link>
                </div>
            </div>
        </div>



    );
};

const Auction = ({ categoryName }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        // Fetch data from the API
        axios.get('https://backend.aslikahani.com/bidding/v1/auction/list')
            .then(response => {
                const categoryData = response.data[categoryName];
                setData(categoryData);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError(error);
                setLoading(false);
            });
    }, [categoryName]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            {/* <!-- Car Auction --> */}
            <section id="jewelry-auction">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 header d-flex justify-content-between">
                            <div className="title d-flex">
                                <img src={require(`../assets/images/catagory02.png`)} alt="" />
                                <div className="text">
                                    <h1>{data.name}</h1>
                                    <p>Online auctions where you can bid now and save money</p>
                                </div>
                            </div>
                            <div className="header-button">
                                <Link to={"/bidding/auction-list/" + data.name} className="view-all btn rounded-pill">View All</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {data.auctions.map((auction) => (
                            <AuctionItem key={auction.id} auction={auction} />
                        ))}
                    </div>
                </div>
                <div className="jewelry-bg">
                    <img src={require(`../assets/images/jwelry-bg.png`)} alt="" />
                </div>
            </section>
            {/* <!-- End Car Auction --> */}
        </>
    )
}
export default Auction;