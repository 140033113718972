
import React from 'react';
import './skeleton.css'


const skeleton = () => {
    return (
        <>
            <div className='container'>
                <div className='sklLoader'>
                    <div className='loadItem skeleton'>
                    </div>
                    <div className='loadItem skeleton'>
                    </div>
                    <div className='loadItem skeleton'>
                    </div>
                    <div className='loadItem skeleton'>
                    </div>
                    <div className='loadItem skeleton'>
                    </div>
                    <div className='loadItem skeleton'>
                    </div>

                </div>
            </div>

        </>
    )
}
export default skeleton;