import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import UserDetails from './userDetails';
import MyBIds from './myBids';
import MyWinnings from './myWinning';

// const UserProfile = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [selectedComponent, setSelectedComponent] = useState('UsrProfile');
//   const [userInfo, setUserInfo] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const accessToken = location.state?.accessToken || localStorage.getItem('accessToken');
//   const refreshToken = localStorage.getItem('refreshToken');

//   useEffect(() => {
//     const fetchUserInfo = async () => {
//       if (!accessToken) {
//         setError("User logout");
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await axios.get('https://backend.aslikahani.com/bidding/v1/profile/get', {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });
//         setUserInfo(response.data);
//         setLoading(false);
//       } catch (err) {
//         if (err.response && err.response.status === 401) {
//           // If 401 Unauthorized, try refreshing the token
//           await handleTokenRefresh();
//         } else {
//           setError('Error fetching user info. Please try again.');
//           setLoading(false);
//         }
//       }
//     };

//     fetchUserInfo();
//   }, [accessToken]);

//   const handleTokenRefresh = async () => {
//     try {
//       const response = await axios.post('https://backend.aslikahani.com/purity/v1/auth/refresh-token', {
//         refresh_token: refreshToken,
//       });

//       const newAccessToken = response.data.tokens.access;
//       const newRefreshToken = response.data.tokens.refresh;

//       // Update tokens in localStorage
//       localStorage.setItem('accessToken', newAccessToken);
//       localStorage.setItem('refreshToken', newRefreshToken);

//       // Retry fetching user info with the new token
//       setError(null);
//       setLoading(true);
//       const retryResponse = await axios.get('https://backend.aslikahani.com/bidding/v1/profile/get', {
//         headers: {
//           Authorization: `Bearer ${newAccessToken}`,
//         },
//       });
//       setUserInfo(retryResponse.data);
//       setLoading(false);
//     } catch (refreshError) {
//       console.error('Error refreshing token:', refreshError);
//       handleLogout();
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('accessToken');
//     localStorage.removeItem('refreshToken');
//     navigate('/login');
//   };

//   const handleButtonClick = (componentKey) => {
//     setSelectedComponent(componentKey);
//   };

//   const components = {
//     UsrProfile: (
//       <UserDetails
//         userInfo={userInfo}
//         accessToken={accessToken}
//         setUserInfo={setUserInfo}
//       />
//     ),
//     MyBids: <MyBIds accessToken={accessToken} />,
//     MyWinnings: <MyWinnings />,
//   };

//   if (loading) {
//     return <div className="fullPageError">Loading...</div>;
//   }

//   if (error) {
//     return (
//       <div className="fullPageError">
//         {error}
//         <Link to="/">Back to Home</Link>
//       </div>
//     );
//   }

//   if (!userInfo) {
//     return <div className="fullPageError">No user information available.</div>;
//   }

//   return (
//     <>
//       <main>
//         <section id="breadcrumb">
//           <div className="container">
//             <ul className="breadcrumb">
//               <li>
//                 <a href="javascript:void(0)">
//                   Home
//                   <i className="flaticon-right-arrow"></i>
//                 </a>
//               </li>
//               <li>
//                 <a href="javascript:void(0)">Auction</a>
//               </li>
//             </ul>
//           </div>
//           <div className="bg-image aboutUsImg"></div>
//         </section>

//         <section id="dashboard">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-4">
//                 <div className="user-menu">
//                   <div className="user">
//                     <div className="image">
//                       <img src={require(`../assets/images/user.png`)} alt="User" />
//                       <label htmlFor="user-photo">
//                         <i className="fas fa-pencil-alt"></i>
//                       </label>
//                       <input type="file" id="user-photo" className="d-none" />
//                     </div>
//                     <div className="content">
//                       <h4>{userInfo.fname} {userInfo.lname}</h4>
//                       <span>{userInfo.email}</span>
//                     </div>
//                   </div>
//                   <ul className="menu">
//                     <li>
//                       <span
//                         className={selectedComponent === 'UsrProfile' ? 'active' : ''}
//                         onClick={() => handleButtonClick('UsrProfile')}
//                       >
//                         <i className="flaticon-hammer"></i>
//                         Personal Profile
//                       </span>
//                     </li>
//                     <li>
//                       <span
//                         className={selectedComponent === 'MyBids' ? 'active' : ''}
//                         onClick={() => handleButtonClick('MyBids')}
//                       >
//                         <i className="flaticon-settings"></i>
//                         My Bids
//                       </span>
//                     </li>
//                     <li>
//                       <span onClick={handleLogout}>
//                         <i className="flaticon-logout"></i>
//                         Logout
//                       </span>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-lg-8">
//                 {components[selectedComponent]}
//               </div>
//             </div>
//           </div>
//         </section>
//       </main>
//     </>
//   );
// };

// export default UserProfile;




const UserProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState('UsrProfile');
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accessToken = location.state?.accessToken || localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const fetchUserInfo = async () => {
    if (!accessToken) {
      setError("User logout");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get('https://backend.aslikahani.com/bidding/v1/profile/get', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUserInfo(response.data);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        await handleTokenRefresh();
      } else {
        setError('Error fetching user info. Please try again.');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, [accessToken]);

  const handleTokenRefresh = async () => {
    try {
      const response = await axios.post('https://backend.aslikahani.com/purity/v1/auth/refresh-token', {
        refresh_token: refreshToken,
      });

      const newAccessToken = response.data.tokens.access;
      const newRefreshToken = response.data.tokens.refresh;

      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);

      setError(null);
      setLoading(true);
      const retryResponse = await axios.get('https://backend.aslikahani.com/bidding/v1/profile/get', {
        headers: {
          Authorization: `Bearer ${newAccessToken}`,
        },
      });
      setUserInfo(retryResponse.data);
      setLoading(false);
    } catch (refreshError) {
      console.error('Error refreshing token:', refreshError);
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  const handleButtonClick = (componentKey) => {
    setSelectedComponent(componentKey);
  };

  const handleProfileUpdate = () => {
    fetchUserInfo(); // Re-fetch user info after update
  };

  const components = {
    UsrProfile: (
      <UserDetails
        userInfo={userInfo}
        accessToken={accessToken}
        setUserInfo={setUserInfo}
        onProfileUpdate={handleProfileUpdate} // Pass the callback
      />
    ),
    MyBids: <MyBIds accessToken={accessToken} />,
    MyWinnings: <MyWinnings />,
  };

  if (loading) {
    return <div className="fullPageError">Loading...</div>;
  }

  if (error) {
    return (
      <div className="fullPageError">
        {error}
        <Link to="/">Back to Home</Link>
      </div>
    );
  }

  if (!userInfo) {
    return <div className="fullPageError">No user information available.</div>;
  }

  return (
    <>
      <main>
        <section id="breadcrumb">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a href="javascript:void(0)">
                  Home
                  <i className="flaticon-right-arrow"></i>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">Auction</a>
              </li>
            </ul>
          </div>
          <div className="bg-image aboutUsImg"></div>
        </section>

        <section id="dashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="user-menu">
                  <div className="user">
                    <div className="image">
                      <img src={require(`../assets/images/user.png`)} alt="User" />
                      <label htmlFor="user-photo">
                        <i className="fas fa-pencil-alt"></i>
                      </label>
                      <input type="file" id="user-photo" className="d-none" />
                    </div>
                    <div className="content">
                      <h4>{userInfo.fname} {userInfo.lname}</h4>
                      <span>{userInfo.email}</span>
                    </div>
                  </div>
                  <ul className="menu">
                    <li>
                      <span
                        className={selectedComponent === 'UsrProfile' ? 'active' : ''}
                        onClick={() => handleButtonClick('UsrProfile')}
                      >
                        <i className="flaticon-hammer"></i>
                        Personal Profile
                      </span>
                    </li>
                    <li>
                      <span
                        className={selectedComponent === 'MyBids' ? 'active' : ''}
                        onClick={() => handleButtonClick('MyBids')}
                      >
                        <i className="flaticon-settings"></i>
                        My Bids
                      </span>
                    </li>
                    <li>
                      <span onClick={handleLogout}>
                        <i className="flaticon-logout"></i>
                        Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                {components[selectedComponent]}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default UserProfile;