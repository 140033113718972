
import React, { useEffect, useState } from "react";
import RegisterWrapper from "../components/registerWrapper";
import { Link } from "react-router-dom";

const AboutUs = () => {


    return (
        <>
            <main>

                <section id="breadcrumb">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li>
                                <a href="javascript:void(0)">
                                    Home
                                    <i className="flaticon-right-arrow"></i>
                                </a>
                            </li>
                            <li>
                                <a href="about-us.html">
                                    About Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="bg-image aboutUsImg">

                    </div>
                </section>


                <section id="about-header">
                    <div className="container bg">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="item">
                                <img src={require(`../assets/images/about01.png`)} alt=""/>
                                {/* <p>AWARD-WINNING TEAM</p> */}
                            </div>
                                <h3>ABOUT US</h3>
                                
                                <p>Welcome to the *One and Only Bidding App—your trusted platform for acquiring gold in all its splendid forms, whether it be jewels, coins, bullion, or even antique items. This unique initiative is brought to you by **SriStar Gold Company*, a leader in the gold industry known for its commitment to offering the best deals and services to its customers.</p>
                                <p>At the heart of our mission is a dedication to providing gold at the most reasonable and fair prices. We achieve this by minimizing making and wastage charges, ensuring that every purchase you make is as economical as possible without compromising on quality. Our platform not only democratizes access to gold but also ensures transparency and fairness in every transaction.
                                </p>
                                <p>
                                Whether you're a seasoned investor or a first-time buyer, our bidding app is designed to meet all your gold-related needs with ease and convenience. Join us on this golden journey and experience a new way of buying gold, where trust and value are paramount.

                                </p>
                                <p>For more details about our parent company and its legacy, please visit <Link to="https://stargoldcompany.com/">SriStar Gold Company</Link>.</p>
                                {/* <h1><span>OVER 60</span><br/> YEARS EXPERIENCE</h1>
                        <p>Innovation have made us leaders in auctions platform</p>
                        <div className="item-area d-flex">
                            <div className="item text-center">
                                <img src={require(`../assets/images/about01.png`)} alt=""/>
                                <p>AWARD-WINNING TEAM</p>
                            </div>
                            <div className="item text-center ml-3">
                                <img src={require(`../assets/images/about02.png`)} alt=""/>
                                <p>AFFILIATIONS</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="image">
                            <img src={require(`../assets/images/about.png`)} className="img-fluid" alt=""/>
                        </div>
                    </div> */}
                            </div>
                        </div>
                    </div>
                </section>


                {/* <section id="business-counter" className="start">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="item">
                            <div className="title d-flex">
                                <div className="timer" data-to="62" data-speed="5000">62</div>
                                <span>M</span>
                            </div>
                            <p>ITEMS AUCTIONED</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="item">
                            <div className="title d-flex">
                                <span>₹</span>
                                <div className="timer" data-to="887" data-speed="5000">887</div>
                                <span>M</span>
                            </div>
                            <p>IN SECURE BIDS</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="item">
                            <div className="title d-flex">
                                <div className="timer" data-to="63" data-speed="5000">63</div>
                                <span>M</span>
                            </div>
                            <p>ITEMS AUCTIONED</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="item">
                            <div className="title d-flex">
                                <div className="timer" data-to="5" data-speed="5000">5</div>
                                <span>K</span>
                            </div>
                            <p>AUCTION EXPERTS</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section id="expect">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="content">
                            <div className="header">
                                <h1>What Can You Expect?</h1>
                                <p>Voluptate aut blanditiis accusantium officiis expedita dolorem inventore odio
                                    reiciendis obcaecati quod nisi quae</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="item">
                                        <img src={require(`../assets/images/expect01.png`)} alt=""/>
                                        <h5>Real-Time Auction</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="item">
                                        <img src={require(`../assets/images/expect02.png`)} alt=""/>
                                        <h5>Real-Time Auction</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="item">
                                        <img src={require(`../assets/images/expect03.png`)} alt=""/>
                                        <h5>Real-Time Auction</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="item">
                                        <img src={require(`../assets/images/expect04.png`)} alt=""/>
                                        <h5>Real-Time Auction</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="item">
                                        <img src={require(`../assets/images/expect05.png`)} alt=""/>
                                        <h5>Real-Time Auction</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="item">
                                        <img src={require(`../assets/images/expect06.png`)} alt=""/>
                                        <h5>Real-Time Auction</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="bg">
                            <img src={require(`../assets/images/overview-bg.png`)} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>


       <RegisterWrapper/>


        <section id="team-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="header text-center">
                            <h1>Meet The Management Team</h1>
                            <p>Our team consists of passionate and talented individuals invested in your success.</p>
                        </div>
                    </div>
                </div>
                <div className="team">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/team1.png`)} alt=""/>
                                </div>
                                <div className="content">
                                    <h5>
                                        <a href="#">
                                            Kent Quinn
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/team2.png`)} alt=""/>
                                </div>
                                <div className="content">
                                    <h5>
                                        <a href="#">
                                            Dustin Day
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/team3.png`)} alt=""/>
                                </div>
                                <div className="content">
                                    <h5>
                                        <a href="#">
                                            Antonia Little
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/team4.png`)} alt=""/>
                                </div>
                                <div className="content">
                                    <h5>
                                        <a href="#">
                                            Marie Wolfe
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


         <section id="customer-comments">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title text-center">
                            <h1>Don’t Just Take Our Word For It!</h1>
                            <p>Our hard work is paying off. Great reviews from amazing customers.</p>
                        </div>
                    </div>
                </div>
                <div className="customers-slider">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="subBannerGrid">
                                <div className="item">
                                    <div className="comment">
                                        <p>
                                            I can't stop bidding! It's a great way to spend some time and I want
                                            everything on
                                            Sbidu.
                                        </p>
                                    </div>
                                    <div className="author d-flex">
                                        <div className="image">
                                            <img src={require(`../assets/images/client01.png`)} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <h5>
                                                <a href="#">Alexis Moore</a>
                                            </h5>
                                            <div className="icon">
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="comment">
                                        <p>
                                            This was my first time, but it was exciting. I will try it again. Thank you
                                            so much.
                                        </p>
                                    </div>
                                    <div className="author d-flex">
                                        <div className="image">
                                            <img src={require(`../assets/images/client03.png`)} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <h5>
                                                <a href="#">Emma Powell</a>
                                            </h5>
                                            <div className="icon">
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="comment">
                                        <p>
                                            I came I saw I won. Love what I have won, and will try to win something
                                            else.
                                        </p>
                                    </div>
                                    <div className="author d-flex">
                                        <div className="image">
                                            <img src={require(`../assets/images/client02.png`)} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <h5>
                                                <a href="#">Darin Griffin</a>
                                            </h5>
                                            <div className="icon">
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="comment">
                                        <p>
                                            I can't stop bidding! It's a great way to spend some time and I want
                                            everything on
                                            Sbidu.
                                        </p>
                                    </div>
                                    <div className="author d-flex">
                                        <div className="image">
                                            <img src={require(`../assets/images/client04.png`)} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <h5>
                                                <a href="#">David Ben</a>
                                            </h5>
                                            <div className="icon">
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="comment">
                                        <p>
                                            I came I saw I won. Love what I have won, and will try to win something
                                            else.
                                        </p>
                                    </div>
                                    <div className="author d-flex">
                                        <div className="image">
                                            <img src={require(`../assets/images/client05.png`)} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <h5>
                                                <a href="#">William Shakespeare</a>
                                            </h5>
                                            <div className="icon">
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                                <i className="fas fa-star yellow"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section> */}

            </main>
        </>
    );
};

export default AboutUs;