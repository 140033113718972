
import React from 'react';


const CommentsSlider = () => {
    return (
        <>
            {/* <!-- Customer Comments  --> */}
            <section id="customer-comments">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title text-center">
                                <h1>Don’t Just Take Our Word For It!</h1>
                                <p>Our hard work is paying off. Great reviews from amazing customers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="customers-slider">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="subBannerGrid">
                                    <div className="item">
                                        <div className="comment">
                                            <p>
                                                I can't stop bidding! It's a great way to spend some time and I want
                                                everything on
                                                Bid.
                                            </p>
                                        </div>
                                        <div className="author d-flex">
                                            <div className="image">
                                                <img src={require(`../assets/images/client01.png`)} alt="" />
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <h5>
                                                    <a href="#">Alexis Moore</a>
                                                </h5>
                                                <div className="icon">
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="comment">
                                            <p>
                                                This was my first time, but it was exciting. I will try it again. Thank you
                                                so much.
                                            </p>
                                        </div>
                                        <div className="author d-flex">
                                            <div className="image">
                                                <img src={require(`../assets/images/client03.png`)} alt="" />
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <h5>
                                                    <a href="#">Emma Powell</a>
                                                </h5>
                                                <div className="icon">
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="comment">
                                            <p>
                                                I came I saw I won. Love what I have won, and will try to win something
                                                else.
                                            </p>
                                        </div>
                                        <div className="author d-flex">
                                            <div className="image">
                                                <img src={require(`../assets/images/client02.png`)} alt="" />
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <h5>
                                                    <a href="#">Darin Griffin</a>
                                                </h5>
                                                <div className="icon">
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="comment">
                                            <p>
                                                I can't stop bidding! It's a great way to spend some time and I want
                                                everything on
                                                Bid.
                                            </p>
                                        </div>
                                        <div className="author d-flex">
                                            <div className="image">
                                                <img src={require(`../assets/images/client04.png`)} alt="" />
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <h5>
                                                    <a href="#">David Ben</a>
                                                </h5>
                                                <div className="icon">
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="comment">
                                            <p>
                                                I came I saw I won. Love what I have won, and will try to win something
                                                else.
                                            </p>
                                        </div>
                                        <div className="author d-flex">
                                            <div className="image">
                                                <img src={require(`../assets/images/client05.png`)} alt="" />
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <h5>
                                                    <a href="#">William Shakespeare</a>
                                                </h5>
                                                <div className="icon">
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                    <i className="fas fa-star yellow"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* <!-- Customer Comments  --> */}
        </>
    )
}
export default CommentsSlider;