
import React from "react";

const MyWinnings = () => {


    return (
        <>
            <div className="winning-bids">
                            <div className="header">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="title">
                                            <h3>Winning Bids</h3>
                                        </div>
                                        <div className="search d-flex justify-content-between">
                                            <form action="#">
                                                <input type="text" placeholder="Item Name"/>
                                                <button>
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </form>
                                            <div className="sort d-flex align-items-center">
                                                <div className="name">
                                                    <span>Sort By :</span>
                                                </div>
                                                <div className="sort-by">
                                                    <select className="form-control">
                                                        <option value="all">All</option>
                                                        <option value="a-z">A-Z</option>
                                                        <option value="date">Date</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="auction-item">
                                        <div className="image">
                                            <a href="#">
                                                <img src="assets/img/car01.png" alt=""/>
                                            </a>
                                            <a href="#" className="star">
                                                <i className="far fa-star"></i>
                                            </a>
                                            <a href="#" className="hammer">
                                                <i className="flaticon-hammer"></i>
                                            </a>
                                        </div>
                                        <div className="auction-content">
                                            <h5>2020 Hyundai Elantra, Sel</h5>
                                            <div className="bid d-flex justify-content-between">
                                                <div className="buy-now-price d-flex">
                                                    <i className="flaticon-money"></i>
                                                    <p className="d-flex flex-column">Sold
                                                        <span>₹5000.00</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button text-center mt-3">
                                            <a href="#">Buy Now</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="auction-item">
                                        <div className="image">
                                            <a href="#">
                                                <img src="assets/img/car03.png" alt=""/>
                                            </a>
                                            <a href="#" className="star">
                                                <i className="far fa-star"></i>
                                            </a>
                                            <a href="#" className="hammer">
                                                <i className="flaticon-hammer"></i>
                                            </a>
                                        </div>
                                        <div className="auction-content">
                                            <h5>2019 Mercedes-Benz C, 300</h5>
                                            <div className="bid d-flex justify-content-between">
                                                <div className="buy-now-price d-flex">
                                                    <i className="flaticon-money"></i>
                                                    <p className="d-flex flex-column">Sold
                                                        <span>₹5000.00</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button text-center mt-3">
                                            <a href="#">Buy Now</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="auction-item">
                                        <div className="image">
                                            <a href="#">
                                                <img src="assets/img/car04.png" alt=""/>
                                            </a>
                                            <a href="#" className="star">
                                                <i className="far fa-star"></i>
                                            </a>
                                            <a href="#" className="hammer">
                                                <i className="flaticon-hammer"></i>
                                            </a>
                                        </div>
                                        <div className="auction-content">
                                            <h5>2014 KIA Sorento, LX</h5>
                                            <div className="bid d-flex justify-content-between">
                                                <div className="buy-now-price d-flex">
                                                    <i className="flaticon-money"></i>
                                                    <p className="d-flex flex-column">Sold
                                                        <span>₹5000.00</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button text-center mt-3">
                                            <a href="#">Buy Now</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="auction-item">
                                        <div className="image">
                                            <a href="#">
                                                <img src="assets/img/car05.png" alt=""/>
                                            </a>
                                            <a href="#" className="star">
                                                <i className="far fa-star"></i>
                                            </a>
                                            <a href="#" className="hammer">
                                                <i className="flaticon-hammer"></i>
                                            </a>
                                        </div>
                                        <div className="auction-content">
                                            <h5>2019 Subaru Crosstrek, Premium</h5>
                                            <div className="bid d-flex justify-content-between">
                                                <div className="buy-now-price d-flex">
                                                    <i className="flaticon-money"></i>
                                                    <p className="d-flex flex-column">Sold
                                                        <span>₹5000.00</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button text-center mt-3">
                                            <a href="#">Buy Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        </>
    );
};

export default MyWinnings;