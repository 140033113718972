
import React from 'react';
import { Link } from 'react-router-dom';


const SubscribeWrapper = () => {
    return (
        <>
            <div className="subscribe">
            <div className="container area">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="image">
                            <img src={require(`../assets/images/newslater.png`)} className="img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content">
                            <div className="title">
                                <h4>Subscribe to Bid</h4>
                                <h2>To Get Exclusive Benefits</h2>
                            </div>
                            <form action="#">
                                {/* <input type="text" placeholder="Enter Your Email" /> */}
                                <Link to={"/contact"}>
                                <button type="submit">Subscribe</button>
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default SubscribeWrapper;