
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "../components/skeleton/skeleton";
import { useLocation } from "react-router-dom";

const ViewActions = () => {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (location) {
            var tmp = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length);
            // setData(tmp) 
            tmp = tmp.substring(1, tmp.length);
          }
        const url = "https://backend.aslikahani.com/bidding/v1/auction/list/filter?category=" + tmp;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);
    // const catagoryType = data.find(category => category.name === "Bangles");
    const catagoryType = data.name;

    if (loading) {
        return <Skeleton />;
    }
    return (
        <>
            <main>

                <section id="breadcrumb">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li>
                                <a href="javascript:void(0)">
                                    Home
                                    <i className="flaticon-right-arrow"></i>
                                </a>
                            </li>
                            <li>
                                <a href="about-us.html">
                                    All Auction
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="bg-image aboutUsImg">

                    </div>
                </section>


                <section id="jewelry-auction" className="overlapingInnerSection">
                    <div className="container whiteBgRadious">

                        <div className="row">
                            <div className="col-lg-12 header d-flex justify-content-between">
                                <div className="title d-flex">
                                    <img src={require(`../assets/images/catagory02.png`)} alt="" />
                                    <div className="text">
                                        <h1>{catagoryType.name}</h1>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {catagoryType && (
                                <>
                                    {
                                        data.auctions.map(auction => (
                                            <div className="col-lg-4 col-md-6 col-sm-10">
                                                <div key={auction.id} className="auction-item">
                                                    <div className="image">
                                                         <a href="javascript:void(0)">
                                                            <img src={auction.entity.primary_image} alt="" />
                                                        </a>
                                                        <a href="#" className="star">
                                                            <i className="far fa-star"></i>
                                                        </a>
                                                        <a href="#" className="hammer">
                                                            <i className="flaticon-hammer"></i>
                                                        </a>
                                                    </div>
                                                    <div className="auction-content">
                                                        <h5>{auction.name}</h5>
                                                        <div className="bid d-flex justify-content-between">
                                                            <div className="current-bid d-flex">
                                                                <i className="flaticon-hammer"></i>
                                                                <p className="d-flex flex-column">Current Bid
                                                                    <span>₹{auction.max_selling_price}</span>
                                                                </p>
                                                            </div>
                                                            <div className="buy-now-price d-flex">
                                                                <i className="flaticon-money"></i>
                                                                <p className="d-flex flex-column">Buy Now
                                                                    <span>₹{auction.base_price}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="time d-flex ">
                                                        <div className="countdown" data-countdown="10/31/2020 20:00">{auction.visibility_until}</div>
                                                        <span>{auction.total_bid_count} Bids</span>
                                                    </div>
                                                    <div className="button text-center">
                                                        <Link to={"/auction/" + auction.url}>Submit A Bid</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ViewActions;