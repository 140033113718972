
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faSearch, faBars } from '@fortawesome/free-solid-svg-icons'


const Header = ({ isLoggedIn }) => {
    const [sticky, setSticky] = useState("");

    useEffect(() => {
        // console.log("hello");
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 50 ? "is-sticky" : "";
        setSticky(stickyClass);
        // console.log(stickyClass);
    };

    const classes = ` ${sticky}`;
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <>
            <header>
                <div className={`header-top`}>
                    <div className="container">
                        <div className="content d-flex justify-content-between align-items-center">
                            <ul className="d-flex">
                                <li>
                                    <a href="#" className="mr-3">
                                        <FontAwesomeIcon icon={faHeadset} />
                                        <span> Customer Support</span>
                                        {/* <p>{isLoggedIn ? 'Logged In' : 'Not Logged In'}</p> */}
                                    </a>
                                </li>
                                <li>
                                    <i className="fas fa-globe"></i>
                                    <select name="language" className="select-bar">
                                        <option value="en">En</option>
                                        <option value="az">Az</option>
                                        <option value="ru">Ru</option>
                                    </select>
                                </li>
                            </ul>
                            <div className="my-account d-flex align-items-center">
                                {isLoggedIn ? <>
                                    <Link to="/UserProfile" className="user"><i className="flaticon-user"></i></Link>
                                </> : <>
                                    <Link to="/login" className="login">Login</Link>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`header-bottom ${classes}`}>
                    <nav className="navbar navbar-expand-lg">
                        <div className="container">
                            <div className="logo">
                                <a className="navbar-brand" href="/">
                                    <img src={require(`../../assets/images/logo.png`)} alt="logo" />
                                </a>
                                <button onClick={handleToggle} className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <FontAwesomeIcon icon={faBars} />
                                </button>
                            </div>

                            <div className={`collapse navbar-collapse justify-content-end ${isNavOpen ? 'show' : ''}`}>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/auction">Auction</Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link to="/about">About Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>

                            </div>
                            <div className="search-nav">
                                {/* <span className="icon">
                                <FontAwesomeIcon icon={faSearch} />
                                </span> */}
                                <form className="search-form my-2 my-lg-0">
                                    <input className="form-control mr-sm-2" type="search" placeholder="Search for brand, model...."
                                        aria-label="Search" />
                                    <button className="btn-search my-2 my-sm-0" type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
};

export default Header;