// import React, { useState } from 'react';
// import axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEdit, faFileEdit } from '@fortawesome/free-solid-svg-icons'

// const UserDetails = ({ userInfo, accessToken, setUserInfo }) => {
//   const [formData, setFormData] = useState({
//     fname: userInfo.fname || '',
//     lname: userInfo.lname || '',
//     email: userInfo.email || '',
//     address_line_1: userInfo.address_line_1 || '',
//     address_line_2: userInfo.address_line_2 || '',
//     city: userInfo.city || '',
//     pincode: userInfo.pincode || '',
//   });

//   const [isEditing, setIsEditing] = useState(false);
//   const [updating, setUpdating] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     setUpdating(true);
//     try {
//       const response = await axios.post('https://backend.aslikahani.com/bidding/v1/profile/update', formData, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });

//       setUserInfo(response.data);
//       alert('Profile updated successfully');
//       setIsEditing(false); // Exit edit mode after successful update
//     } catch (error) {
//       console.error('Error updating profile:', error);
//       alert('Error updating profile');
//     } finally {
//       setUpdating(false);
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleCancelClick = () => {
//     setIsEditing(false);
//     // Reset form data to original user info if cancel is clicked
//     setFormData({
//       fname: userInfo.fname || '',
//       lname: userInfo.lname || '',
//       email: userInfo.email || '',
//       address_line_1: userInfo.address_line_1 || '',
//       address_line_2: userInfo.address_line_2 || '',
//       city: userInfo.city || '',
//       pincode: userInfo.pincode || '',
//     });
//   };

//   if (updating) {
//     return <div>Updating profile...</div>;
//   }

//   return (
//     <div className="row">
//       <div className="col-lg-12">
//         <div className="details">
//           <div className="header d-flex justify-content-between">
//             <h3>Personal Details</h3>
//             {!isEditing && (
//               <button className='editingBtn' onClick={handleEditClick}>
//                 <FontAwesomeIcon icon={faEdit} />
//                 Edit</button>
//             )}
//           </div>
//           <form onSubmit={handleFormSubmit}>
//             <ul>
//               <li>
//                 <div className="info">Name</div>
//                 <div className="info-value">
//                   {isEditing ? (
//                     <>
//                       <input
//                         type="text"
//                         name="fname"
//                         value={formData.fname}
//                         onChange={handleInputChange}
//                         placeholder="First Name"
//                       />
//                       <input
//                         type="text"
//                         name="lname"
//                         value={formData.lname}
//                         onChange={handleInputChange}
//                         placeholder="Last Name"
//                       />
//                     </>
//                   ) : (
//                     <span>{formData.fname} {formData.lname}</span>
//                   )}
//                 </div>
//               </li>
//               <li>
//                 <div className="info">Address</div>
//                 <div className="info-value">
//                   {isEditing ? (
//                     <>
//                       <input
//                         type="text"
//                         name="address_line_1"
//                         value={formData.address_line_1}
//                         onChange={handleInputChange}
//                         placeholder="Address Line 1"
//                       />
//                       <input
//                         type="text"
//                         name="address_line_2"
//                         value={formData.address_line_2}
//                         onChange={handleInputChange}
//                         placeholder="Address Line 2"
//                       />
//                       <input
//                         type="text"
//                         name="city"
//                         value={formData.city}
//                         onChange={handleInputChange}
//                         placeholder="City"
//                       />
//                       <input
//                         type="text"
//                         name="pincode"
//                         value={formData.pincode}
//                         onChange={handleInputChange}
//                         placeholder="Pincode"
//                       />
//                     </>
//                   ) : (
//                     <span>
//                       {formData.address_line_1} {formData.address_line_2}, {formData.city}, {formData.pincode}
//                     </span>
//                   )}
//                 </div>
//               </li>
//               <li>
//                 <div className="info">Email</div>
//                 <div className="info-value">
//                   {isEditing ? (
//                     <input
//                       type="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleInputChange}
//                       placeholder="Email"
//                     />
//                   ) : (
//                     <span>{formData.email}</span>
//                   )}
//                 </div>
//               </li>
//             </ul>
//             {isEditing && (
//               <div className="form-actions">
//                 <button type="submit">Save</button>
//                 <button type="button" onClick={handleCancelClick}>Cancel</button>
//               </div>
//             )}
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserDetails;



import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const UserDetails = ({ userInfo, accessToken, setUserInfo, onProfileUpdate }) => {
  const [formData, setFormData] = useState({
    fname: userInfo.fname || '',
    lname: userInfo.lname || '',
    email: userInfo.email || '',
    address_line_1: userInfo.address_line_1 || '',
    address_line_2: userInfo.address_line_2 || '',
    city: userInfo.city || '',
    pincode: userInfo.pincode || '',
  });

  const [isEditing, setIsEditing] = useState(false);
  const [updating, setUpdating] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const response = await axios.post('https://backend.aslikahani.com/bidding/v1/profile/update', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setUserInfo(response.data); // Update userInfo state in parent component
      setIsEditing(false); // Exit edit mode after successful update

      if (onProfileUpdate) {
        onProfileUpdate(); // Trigger a re-fetch of user info
      }

      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error updating profile');

    } finally {
      setUpdating(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setFormData({
      fname: userInfo.fname || '',
      lname: userInfo.lname || '',
      email: userInfo.email || '',
      address_line_1: userInfo.address_line_1 || '',
      address_line_2: userInfo.address_line_2 || '',
      city: userInfo.city || '',
      pincode: userInfo.pincode || '',
    });
  };

  if (updating) {
    return <div>
      <div className="details">
        <div className="header d-flex justify-content-between">
          <h3> Updating profile...</h3>
        </div>
      </div>
    </div>;
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="details">
          <div className="header d-flex justify-content-between">
            <h3>Personal Details</h3>
            {!isEditing && (
              <button className='editingBtn' onClick={handleEditClick}>
                <FontAwesomeIcon icon={faEdit} />
                Edit</button>
            )}
          </div>
          <form onSubmit={handleFormSubmit}>
            <ul>
              <li>
                <div className="info">Name</div>
                <div className="info-value">
                  {isEditing ? (
                    <>
                      <input
                        type="text"
                        name="fname"
                        value={formData.fname}
                        onChange={handleInputChange}
                        placeholder="First Name"
                      />
                      <input
                        type="text"
                        name="lname"
                        value={formData.lname}
                        onChange={handleInputChange}
                        placeholder="Last Name"
                      />
                    </>
                  ) : (
                    <span>{formData.fname} {formData.lname}</span>
                  )}
                </div>
              </li>
              <li>
                <div className="info">Address</div>
                <div className="info-value">
                  {isEditing ? (
                    <>
                      <input
                        type="text"
                        name="address_line_1"
                        value={formData.address_line_1}
                        onChange={handleInputChange}
                        placeholder="Address Line 1"
                      />
                      <input
                        type="text"
                        name="address_line_2"
                        value={formData.address_line_2}
                        onChange={handleInputChange}
                        placeholder="Address Line 2"
                      />
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        placeholder="City"
                      />
                      <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleInputChange}
                        placeholder="Pincode"
                      />
                    </>
                  ) : (
                    <div>
                      <p>{formData.address_line_1}</p>
                      <p>{formData.address_line_2}</p>
                      <p>{formData.city} {formData.pincode}</p>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <div className="info">Email</div>
                <div className="info-value">
                  {isEditing ? (
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                    />
                  ) : (
                    <span>{formData.email}</span>
                  )}
                </div>
              </li>
            </ul>
            {isEditing && (
              <div className="form-actions">
                <button type="submit">Save</button>
                <button type="button" onClick={handleCancelClick}>Cancel</button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;


