import React from "react";
import SubscribeWrapper from "../subscribe";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
const Footer = () => (
    <footer>
        <div className="footer-bg">
            <img
                src={require(`../../assets/images/footer-top-shape.png`)}
                className="img-fluid"
                alt=""
            />
        </div>
        <SubscribeWrapper />
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="item">
                            <div className="footgDta">
                                <img
                                    src={require(`../../assets/images/logo.png`)}
                                    className="logo logoFooter"
                                    alt=""
                                />
                                <p>
                                    Corp. Off: 280/1, Anniamma Arcade, Sampige Road, 18th Cross
                                    Rd, Malleshwaram, Bengaluru, Karnataka 560003
                                </p>
                                <p>Contact: 6366333444</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="item">
                            <h4>Menu</h4>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="item">
                            <h4>Socials</h4>
                            <ul className="social-md">
                                <li>
                                    <Link to={"https://www.facebook.com/sristargold"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z" /></svg>
                                        <span>Facebook</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"https://www.instagram.com/sristargold/"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z" /></svg>
                                        <span>Instagram</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"https://www.linkedin.com/company/sristargold/?originalSubdomain=in"}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z" /></svg>
                                        <span>Linkedin</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"https://x.com/i/flow/login?redirect_after_login=%2FSriStarGold"}>
                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px"><path d="M 2.8671875 3 L 9.7363281 12.818359 L 2.734375 21 L 5.3808594 21 L 10.919922 14.509766 L 15.460938 21 L 21.371094 21 L 14.173828 10.697266 L 20.744141 3 L 18.138672 3 L 12.996094 9.0097656 L 8.7988281 3 L 2.8671875 3 z"/></svg>
                                        <span>Twitter</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="item">
                            <h4>Location</h4>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31099.53777149134!2d77.57078900000002!3d13.007487!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae175987bfcea9%3A0x4e8aac7669ecfacf!2sSriStar%20Gold%20Company!5e0!3m2!1sen!2sin!4v1723565075441!5m2!1sen!2sin" width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="subFoottLogo">
                                <img
                                    src={require(`../../assets/images/logo.png`)}
                                    className="logo logoFooter"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <ul className="d-flex slbuPUsdk">
                                <li>
                                    <img src={require(`../../assets/images/paypal.png`)} alt="" />
                                </li>
                                <li>
                                    <img src={require(`../../assets/images/visa.png`)} alt="" />
                                </li>
                                <li>
                                    <img
                                        src={require(`../../assets/images/discover.png`)}
                                        alt=""
                                    />
                                </li>
                                <li>
                                    <img
                                        src={require(`../../assets/images/mastercard.png`)}
                                        alt=""
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-12">
                            <p className="sbLIst">
                                © Copyright 2024 | Sristar Gold, Desgined by Digital Engine
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
