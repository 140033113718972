
import React from 'react';


const HowItWorks = () => {
    return (
        <>
            {/* <!-- How It Works --> */}
            <section id="how-it-works">
                <div className="container bg">
                    <div className="title">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>How It Works</h1>
                                <p>Easy 3 steps to win</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/how1.png`)} className="img-fluid" alt="" />
                                </div>
                                <div className="content">
                                    <h3>Sign Up</h3>
                                    <p>No Credit Card Required</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/how2.png`)} className="img-fluid" alt="" />
                                </div>
                                <div className="content">
                                    <h3>Bid</h3>
                                    <p>Bidding is free Only pay if you win</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="item">
                                <div className="image">
                                    <img src={require(`../assets/images/how3.png`)} className="img-fluid" alt="" />
                                </div>
                                <div className="content">
                                    <h3>Win</h3>
                                    <p>Fun - Excitement - Great deals</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End How It Works --> */}
        </>
    )
}
export default HowItWorks;