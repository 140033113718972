import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faKey } from '@fortawesome/free-solid-svg-icons'

const OtpLogin = ({ setIsLoggedIn }) => {
  const [otp, setOtp] = useState('');
  const [phone, setPhone] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [isLoggedIn, setLoginStatus] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setLoginStatus(true);
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend.aslikahani.com/purity/v1/auth/login', {
        phone_number: phone,
        code: otp,
        source: 'Bidding',
      });

      console.log('Login response:', response.data);

      if (response.data && response.data.tokens) {
        const accessToken = response.data.tokens.access;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', response.data.tokens.refresh);
        setIsLoggedIn(true);
        setLoginStatus(true);
        setMessage('Login successful');
        navigate('/UserProfile', { state: { accessToken } });
      } else {
        setMessage('No tokens found in response');
        console.error('No tokens found in response:', response.data);
      }
    } catch (error) {
      setMessage('Error verifying OTP');
      console.error('Error verifying OTP', error);
    }
  };

  const handleSendOtp = async () => {
    try {
      await axios.get(`https://backend.aslikahani.com/purity/v1/auth/generate-otp?phone_number=${phone}&source=Bidding`);
      setOtpSent(true);
      setMessage('OTP sent successfully');
    } catch (error) {
      setMessage('Error sending OTP');
      console.error('Error sending OTP', error);
    }
  };

  return (
    <div>
      <main>

        <section id="breadcrumb">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a href="javascript:void(0)">
                  Home
                  <i className="flaticon-right-arrow"></i>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  Auction
                </a>
              </li>
            </ul>
          </div>
          <div className="bg-image aboutUsImg" >

          </div>
        </section>


        <section id="login">
          <div className="container">
            <div className="account d-flex">
              <div className="left-side">
                {isLoggedIn ? (

                  <></>
                ) : (
                  <div className="header text-center">
                    <h1>HI, THERE</h1>
                    <p>You can log in to your Bidding account here.</p>
                  </div>
                )}

                {isLoggedIn ? (
                  <div className='header text-center'>
                    <h1>You are already logged in</h1>
                    <Link className='proflBtn' to={"/UserProfile"}>Go to Profile</Link>
                  </div>
                ) : (
                  <div className='formdiv'>
                    {!otpSent ? (
                      <>
                        <div className="form-group">
                          <label className='login-email' htmlFor="email">
                            <FontAwesomeIcon icon={faMobile} />
                          </label>
                          <input
                            type="text"
                            value={phone}
                            className="form-control"
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Enter phone number"
                          />
                        </div>
                        <div className=" form-group mb-0 mt-4">
                          <button onClick={handleSendOtp}>Send OTP</button>
                        </div>
                      </>
                    ) : (
                      <>
                        <form onSubmit={handleOtpSubmit}>
                          <div className="form-group">
                            <label className='login-email' htmlFor="email">
                              <FontAwesomeIcon icon={faKey} />
                            </label>
                            <input
                              type="text"
                              value={otp}
                              className="form-control"
                              onChange={(e) => setOtp(e.target.value)}
                              placeholder="Enter OTP"
                            />

                          </div>
                          <div className=" form-group mb-0 mt-4">
                            <button type="submit">Verify OTP</button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                )}
                <div className='errorHandling'>
                  {message && <p className='successMssg'>{message}</p>}
                </div>
              </div>
              <div className="right-side">
                <div className="sign-up">
                  <h2>Let's<br /> Start Bidding</h2>
                  <p>Sign up and create your Account</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default OtpLogin;

