
import React from 'react';
import { Link } from 'react-router-dom';


const RegisterWrapper = () => {
    return (
        <>
            {/* <!-- Register For Free --> */}
            <section className="register-for-free">
                <div className="container">
                    <div className="content overlayImg">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-8">
                                <h2>Register For Free & Start Bidding Now!</h2>
                                <p>From cars to diamonds to iPhones, we have it all.</p>
                            </div>
                            <div className="col-lg-2 col-md-4">
                            <Link to={"/contact"}>Register</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Register For Free --> */}
        </>);
}
export default RegisterWrapper;