
// import React from 'react';
import React, { useState, useEffect } from 'react';
import Skeleton from '../components/skeleton/skeleton'
import { Link } from 'react-router-dom';



const CatagorySlider = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://backend.aslikahani.com/bidding/v1/category/list')
            .then(response => response.json())
            .then(data => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
            console.log(data);
    }, []);

    if (loading) {
        return <Skeleton/>;
    }
    return (
        <>
            {/* <!-- Catagory Slider --> */}
            <section id="catagory-slider">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h5>Browse the highlights</h5>
                        </div>
                        <div className="col-lg-12 slider">
                            <div className="subBannerGrid ">
                            {data.map(item => (
                            <div  className="item" key={item.id}>
                                    <Link to={"/bidding/auction-list/" + item.name}>
                                        <img src={item.image} alt="" />
                                        <span>{item.name}</span>
                                    </Link>
                                </div>
                                  ))}
                                {/* <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory01.png`)} alt="" />
                                        <span>Vehicles</span>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory02.png`)} alt="" />
                                        <span>Jewelry</span>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory03.png`)} alt="" />
                                        <span>Watches</span>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory04.png`)} alt="" />
                                        <span>Electronics</span>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory05.png`)} alt="" />
                                        <span>Sports</span>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory06.png`)} alt="" />
                                        <span>Real Estate</span>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory07.png`)} alt="" />
                                        <span>Arts</span>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#">
                                        <img src={require(`../assets/images/catagory08.png`)} alt="" />
                                        <span>Coins</span>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Catagory Slider --> */}
        </>
    )
}
export default CatagorySlider;