
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const PopularAuction = () => {
        const [data, setData] = useState(null);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        useEffect(() => {
            // Fetch data from the API
            axios.get('https://backend.aslikahani.com/bidding/v1/auction/list')
                .then(response => {
                    const categoryData = response.data.popular_auction;
                    setData(categoryData);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setError(error);
                    setLoading(false);
                });
        }, []);
    
        if (loading) {
            return <div>Loading...</div>;
        }
    
        if (error) {
            return <div>Error: {error.message}</div>;
        }
    return(
        <>
        {/* <!-- Popular Auctions --> */}
        <section id="popular-auctions">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title text-center">
                                <span>Closing Within 24 Hours</span>
                                <h1>Popular Auctions</h1>
                                <p>Bid and win great deals,Our auction process is simple, efficient, and transparent.</p>
                            </div>
                        </div>
                    </div>
                    <div className="popular-products">
                        <div className="row">
                        {data.slice(0, 6).map(categoryData => (
                            <div className="col-lg-6" key={categoryData.id}>
                                <div className="popular-item d-flex">
                                    <div className="image">
                                        <a href="#">
                                            <img src={categoryData.entity.primary_image} alt={categoryData.entity.name} />
                                        </a>
                                        <a href="#" className="hammer">
                                            <i className="flaticon-hammer"></i>
                                        </a>
                                    </div>
                                    <div className="content text-center">
                                        <h5>
                                            <a href="#">{categoryData.name}</a>
                                        </h5>
                                        <div className="current-bid d-flex">
                                            <i className="flaticon-hammer"></i>
                                            <p className="d-flex flex-column">Current Bid
                                                <span>₹{categoryData.base_price}</span>
                                            </p>
                                        </div>
                                        <p className="total-bid">Total Bids :
                                            <span>230 Bids</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>

                    </div>
                </div>
                <div className="popular-bg">
                    <img src={require(`../assets/images/gold/bg-gold-1.jpg`)} alt="" />
                </div>
            </section>
            {/* <!-- End Popular Auctions --> */}
        </>
    )
}
export default PopularAuction;