import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTimer } from 'react-timer-hook';
import {logout} from '../services/api'

function MyTimer({ expiryTimestamp }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


  return (
    <div style={{textAlign: 'center'}}>
      <div style={{fontSize: '50px'}}>
        <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
    </div>
  );
}
  

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Submit BID
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>Are you sure?</h4>
                <br />
                <p className='text-center'>
                    Bidding at: ₹{props.bidAmount}
                </p>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <Button variant="success" onClick={props.onSubmitBid} disabled={props.loading}>
                    {props.loading ? 'Submitting...' : 'Submit'}
                </Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const AuctionDetails = () => {
    const [modalShow, setModalShow] = useState(false);
    const location = useLocation();
    const [data, setData] = useState(null); // Initially null, not an empty array
    const [elementPriceData, setElementPriceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [index, setIndex] = useState(0);
    const [bidAmount, setBidAmount] = useState('');
    const [isBidValid, setIsBidValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false); // For submit button state
    const navigate = useNavigate();

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const fetchAuctionDetails = async () => {
        try {
            const dtls = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
            const url = `https://backend.aslikahani.com/bidding/v1/auction/get?url=${dtls}`;
            const response = await axios.get(url);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchElementPriceData = async () => {
        try {
            const response = await fetch(`https://backend.aslikahani.com/purity/v1/keystore/values`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setElementPriceData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAuctionDetails();
        fetchElementPriceData();
    }, [location]);

    const handleBidChange = (event) => {
        const value = parseFloat(event.target.value);
        console.log(value);
        setBidAmount(value);

        // Check if the bid amount is within the valid range
        const isValid = value > data.max_bid_price;
        setIsBidValid(isValid);
        setErrorMessage(isValid ? '' : `Bid amount should be more than ₹${data.max_bid_price}`);
    };

    const handleSubmitBid = async () => {
        console.log("Helloo");
        setSubmitting(true); // Disable submit button
        try {
            const token = localStorage.getItem('accessToken');
            const bidData = {
                auction: data.id,
                bid_amount: bidAmount,
            };

            axios.post('https://backend.aslikahani.com/bidding/v1/auction/bid', bidData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(function (response) {
                console.log(response);
                toast.success('Bid submitted successfully!')
                setModalShow(false); // Close the modal after submission
                setBidAmount(''); // Clear the bid amount field
                fetchAuctionDetails(); // Fetch the updated auction details
              })
              .catch(function (error) {
                if(error.response.status==401){
                    logout();
                    navigate("/login");
                } else {
                    toast.error('Failed to submit bid. Please try again.');
                }
              });

            // console.log(response.status);

            // if (response.status === 200) {
            //     // alert('Bid submitted successfully!');
            //     toast.success('Bid submitted successfully!')
            //     setModalShow(false); // Close the modal after submission
            //     setBidAmount(''); // Clear the bid amount field
            //     await fetchAuctionDetails(); // Fetch the updated auction details
            // } else if ( response.status === 401){
            //     console.log("401");
            //     navigate("/login");
            // } else {
            //     console.log("401");
            //     toast.error('Failed to submit bid. Please try again.');
            // }
        } catch (error) {
            console.error('Error submitting bid:', error.response ? error.response.data : error.message);
            toast.error('Error submitting bid. Please try again.');
        } finally {
            setSubmitting(false); // Re-enable submit button
        }
    };

    if (loading) {
        return <>Loading...</>;
    }

    if (!data) {
        return <>No auction details available.</>;
    }

    return (
        <>
            <main>
                {/* Breadcrumb */}
                <section id="breadcrumb">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li>
                                <a href="javascript:void(0)">
                                    Home
                                    <i className="flaticon-right-arrow"></i>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    Auction Details
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="bg-image aboutUsImg"></div>
                </section>
                {/* End Breadcrumb */}

                <section id="details" className="mb-5">
                    <div className="container">
                        <div className="details-slider">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slider-for" style={{ color: 'white' }}>
                                        <div className="image" style={{ textAlign: 'center', padding: '100px' }}>
                                            <Carousel activeIndex={index} onSelect={handleSelect}>
                                                {data.entity.images.map((img, index) => (
                                                    <Carousel.Item key={index}>
                                                        <div className="image">
                                                            <img className='img-fluid' src={img.image} alt={`Image ${index + 1}`} />
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="details-bid">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="header">
                                        <h1>{data.name}</h1>
                                        <ul className="listing-id d-flex">
                                            <li className="listing">{data.entity.name}</li>
                                            <li>{data.entity.short_description}</li>
                                        </ul>
                                        <ul className="current-price">
                                            <li className="header">
                                                <h4>Base Price</h4>
                                                <h2>₹ {data.base_price}</h2>
                                            </li>
                                            
                                            {
                                                data.entity.adhoc_kv.map((kv) => (
                                                    <li>
                                                        <span>{kv.key}</span>
                                                        <h4>{kv.value}</h4>
                                                    </li>
                                                ))
                                            }


                                            {/* <li>
                                                <span>Max Selling Price</span>
                                                <h4>₹{data.max_selling_price}</h4>
                                            </li> */}
                                            <li style={{ color: 'red' }}>
                                                <span>Latest Bid Price</span>
                                                <h4>₹{data.max_bid_price}</h4>
                                            </li>
                                            {/* <li>
                                                <span>Gold 24 kt Price</span>
                                                <h4>₹{elementPriceData[0].value}</h4>
                                            </li> */}
                                            
                                        </ul>
                                        <div className="auction-bid">
                                            <form action="#">
                                                <div className="image">
                                                    <img src={require(`../assets/images/search-icon.png`)} className="img-fluid" alt="" />
                                                </div>
                                                <div className='inptDtns'>
                                                    <input
                                                        type="number"
                                                        placeholder="Enter your bid amount"
                                                        value={bidAmount}
                                                        onChange={handleBidChange}
                                                    />
                                                    {errorMessage && (
                                                        <p className='subMntsTxt' style={{ color: 'red' }}>{errorMessage}</p>
                                                    )}
                                                </div>
                                                <>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => setModalShow(true)}
                                                        disabled={!isBidValid || submitting} // Disable button if bid is not valid or submitting
                                                    >
                                                        Submit A Bid
                                                    </Button>

                                                    <MyVerticallyCenteredModal
                                                        show={modalShow}
                                                        onHide={() => setModalShow(false)}
                                                        bidAmount={bidAmount} // Pass the bid amount to the modal
                                                        onSubmitBid={handleSubmitBid} // Pass the submit handler
                                                        loading={submitting} // Pass the loading state
                                                    />
                                                </>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar">
                                        <div className="title text-center">
                                            <h6>This Auction Ends in:</h6>
                                            <div className="time">
                                                <div className="countdown"><MyTimer expiryTimestamp={new Date(data.visibility_until_timestamp)} /></div>
                                                <div>Days : Hours : Minutes : Seconds</div>
                                            </div>
                                        </div>
                                        <div className="items-area start">
                                            <div className="item d-flex">
                                                <div className="image">
                                                    <img src={require(`../assets/images/icon1.png`)} alt="" />
                                                </div>
                                                <div className="content">
                                                    <div className="timer" data-to="61" data-speed="5000">{data.total_bid_count}</div>
                                                    <span>Active Bids</span>
                                                </div>
                                            </div>
                                            <div className="item d-flex">
                                                <div className="image">
                                                    <img src={require(`../assets/images/icon2.png`)} alt="" />
                                                </div>
                                                <div className="content">
                                                    <div className="timer" data-to="203" data-speed="5000">{Math.floor(Math.random() * (200 - 100 + 1)) + 100}</div>
                                                    <span>Watching</span>
                                                </div>
                                            </div>
                                            <div className="item d-flex">
                                                <div className="image">
                                                    <img src={require(`../assets/images/icon3.png`)} alt="" />
                                                </div>
                                                <div className="content">
                                                    <div className="timer" data-to="83" data-speed="5000">{data.total_bid_count}</div>
                                                    <span>Total Bids</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="details-content">
                        <div className="container">
                            <ul className="nav d-flex">
                                <li className="nav-item">
                                    <a href="#description" data-toggle="tab" className="description nav-link active">
                                        <div className="image">
                                            <img src={require(`../assets/images/tab1.png`)} alt="" />
                                        </div>
                                        Description
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="container">
                        <div className="tab-content">
                            <div className="tab-pane fade active show">
                                <div className='renderData' dangerouslySetInnerHTML={{ __html: data.entity.detailed_description }} />
                            </div>
                        </div>
                    </div> */}
                </section>
            </main>
        </>
    );
};

 export default AuctionDetails;