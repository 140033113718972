// import React, { useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faMailBulk, faComments } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import { toast } from "react-toastify";

// const ContactUs = () => {
//     const [formData, setFormData] = useState({
//         full_name: '',
//         phone_number: '',
//         email: ''
//     });

//     const [responseMessage, setResponseMessage] = useState("");

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.id]: e.target.value
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('https://backend.aslikahani.com//purity/v1/leads/bidding-contactus/submit', formData);
//             if (response.status === 200) {
//                 setResponseMessage("Your message has been sent successfully!");
//                 toast.success("Your message has been sent successfully!")
//             }
//         } catch (error) {
//             setResponseMessage("There was an error sending your message. Please try again.");
//             toast.error("There was an error sending your message. Please try again.")
//         }
//     };

//     return (
//         <>
//             <main>
//                 <section id="breadcrumb">
//                     <div className="container">
//                         <ul className="breadcrumb">
//                             <li>
//                                 <a href="javascript:void(0)">
//                                     Home
//                                     <i className="flaticon-right-arrow"></i>
//                                 </a>
//                             </li>
//                             <li>
//                                 <a href="javascript:void(0)">
//                                     Auction
//                                 </a>
//                             </li>
//                         </ul>
//                     </div>
//                     <div className="bg-image aboutUsImg"></div>
//                 </section>
//                 <section id="contact-form">
//                     <div className="container ">
//                         <div className="bg">
//                             <div className="row">
//                                 <div className="col-lg-12">
//                                     <div className="header text-center">
//                                         <h4>Contact Us</h4>
//                                         <h1>Get In Touch</h1>
//                                         <p>We'd love to hear from you! Let us know how we can help.</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-lg-8">
//                                     <div className="formdiv">
//                                         <form onSubmit={handleSubmit}>
//                                             <div className="form-group">
//                                                 <label htmlFor="full_name">
//                                                     <FontAwesomeIcon icon={faUser} />
//                                                 </label>
//                                                 <input
//                                                     id="full_name"
//                                                     className="form-control"
//                                                     type="text"
//                                                     placeholder="Enter Your Name"
//                                                     value={formData.full_name}
//                                                     onChange={handleChange}
//                                                     required
//                                                 />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="phone_number">
//                                                     <FontAwesomeIcon icon={faComments} />
//                                                 </label>
//                                                 <input
//                                                     id="phone_number"
//                                                     className="form-control"
//                                                     type="number"
//                                                     placeholder="Enter Your Mobile Number"
//                                                     value={formData.phone_number}
//                                                     onChange={handleChange}
//                                                     required
//                                                 />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="email">
//                                                     <FontAwesomeIcon icon={faMailBulk} />
//                                                 </label>
//                                                 <input
//                                                     id="email"
//                                                     className="form-control"
//                                                     type="email"
//                                                     placeholder="Enter Your Email ID"
//                                                     value={formData.email}
//                                                     onChange={handleChange}
//                                                     required
//                                                 />
//                                             </div>
//                                             <div className="form-group justify-content-center mb-0">
//                                                 <button type="submit">
//                                                     SEND MESSAGE
//                                                 </button>
//                                             </div>
//                                         </form>
//                                         <div className="responseMsg">
//                                             {responseMessage && (
//                                                 <p style={{ color: responseMessage.includes("successfully") ? "green" : "red" }}>
//                                                     {responseMessage}
//                                                 </p>
//                                             )}
//                                         </div>˝
//                                     </div>
//                                 </div>
//                                 <div className="col-lg-4">
//                                     <img src={require(`../assets/images/contact.png`)} className="img-fluid" alt="" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </main>
//         </>
//     );
// };

// export default ContactUs;

import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMailBulk, faComments } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from "react-toastify";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        full_name: '',
        phone_number: '',
        email: ''
    });

    const [responseMessage, setResponseMessage] = useState("");

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://backend.aslikahani.com//purity/v1/leads/bidding-contactus/submit', formData);
            if (response.status === 200) {
                setResponseMessage("Your message has been sent successfully!");
                toast.success("Your message has been sent successfully!");
                setFormData({
                    full_name: '',
                    phone_number: '',
                    email: ''
                }); // Reset form fields
            }
        } catch (error) {
            setResponseMessage("There was an error sending your message. Please try again.");
            toast.error("There was an error sending your message. Please try again.");
        }
    };

    return (
        <>
            <main>
                <section id="breadcrumb">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li>
                                <a href="javascript:void(0)">
                                    Home
                                    <i className="flaticon-right-arrow"></i>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    Auction
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="bg-image aboutUsImg"></div>
                </section>
                <section id="contact-form">
                    <div className="container ">
                        <div className="bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="header text-center">
                                        <h4>Contact Us</h4>
                                        <h1>Get In Touch</h1>
                                        <p>We'd love to hear from you! Let us know how we can help.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="formdiv">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="full_name">
                                                    <FontAwesomeIcon icon={faUser} />
                                                </label>
                                                <input
                                                    id="full_name"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter Your Name"
                                                    value={formData.full_name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phone_number">
                                                    <FontAwesomeIcon icon={faComments} />
                                                </label>
                                                <input
                                                    id="phone_number"
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Enter Your Mobile Number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">
                                                    <FontAwesomeIcon icon={faMailBulk} />
                                                </label>
                                                <input
                                                    id="email"
                                                    className="form-control"
                                                    type="email"
                                                    placeholder="Enter Your Email ID"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group justify-content-center mb-0">
                                                <button type="submit">
                                                    SEND MESSAGE
                                                </button>
                                            </div>
                                        </form>
                                        <div className="responseMsg">
                                            {responseMessage && (
                                                <p style={{ color: responseMessage.includes("successfully") ? "green" : "red" }}>
                                                    {responseMessage}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <img src={require(`../assets/images/contact.png`)} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ContactUs;
